<template>
  <div class=" text-left register">
    <mast-head></mast-head>
    <b-container class="text-left">
      <b-row>
        <b-col>
        </b-col>
        <b-col md="8" cols="12">
          <h2 class="color2 mb-5">Register</h2>
        </b-col>
        <b-col>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col md="8" cols="12" class="mx-md-auto ">
          If your organisation is based in the county of Somerset, these resources are freely available to you. They are
          also free if you are outside the area, if your organisation has arranged access.
          <br/>
          <br/>
          Please register to use these resources, this is so your learning can be saved so you can unlock the quiz and
          achieve your certificate.
          <br/>
          <br/>
          The information gathered will be used by Somerset Council to track which agencies and what type of roles are
          accessing it, to help identify any gaps, and may be provided to agencies using the materials, so that they can
          see who in their agency has completed the training.
          <br/>
          <br/>
          For more information on our Privacy policy please visit <a
            href="https://www.somerset.gov.uk/our-information/privacy-notices-and-cookies/" target="_blank">https://www.somerset.gov.uk/our-information/privacy-notices-and-cookies/</a>
          <br/>
          <br/>
          If you are outside the Somerset area, please contact <a
            href="mailto:communitysafetyteam@somerset.gov.uk">communitysafetyteam@somerset.gov.uk</a> to arrange access
          to these materials.
          <br/>
          <br/>
          We can arrange for access for individuals, or for organisations, but there might be a fee involved. Please get
          in touch to discuss your requirements.
          <br/>
          <br/>
          If your organisation/agency has not accessed these materials before, you may have difficulties registering.
          Please attempt registration, if you receive the message, ‘this website is restricted to the Somerset area’
          please contact <a
            href="mailto:communitysafetyteam@somerset.gov.uk">communitysafetyteam@somerset.gov.uk</a>. They will enable
          registration for you.
          <br/>
          <br/>
          If you experience any technical issues with using this site, please contact <a
            href="mailto:somersetdomesticabuse@desq.co.uk">somersetdomesticabuse@desq.co.uk</a> with a description of
          the problem.

        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col md="8" cols="12" class="mx-md-auto ">
          <form @submit.prevent="getWhiteListedEmails" class="mb-3">
            <div class="form-group required ">
                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/name.png'  )" alt="Info icon"/>
                  </span>

              <input aria-label="First name" v-model="forename" class="form-control " name="forename"
                     id="forename" type="text" autocomplete="given-name" required="" placeholder="First name">

            </div>
            <div class="form-group required">
                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/name.png'  )" alt="Info icon"/>
                  </span>
              <input aria-label="Last name" v-model="lastname" class="form-control " name="lastname"
                     id="lastname" type="text" autocomplete="family-name" placeholder="Last name"
                     required="">
            </div>

            <div class="form-group required">
                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/name.png'  )" alt="Info icon"/>
                  </span>
              <b-form-select @change="selectGPPractice"
                             aria-label="Select role" v-model="selectedRole" :options="optionsRole"
                             class=""
                             :required="selectedRole === '' || selectedRole!=='none'"
              ></b-form-select>
            </div>
            <div v-if="selectedRole === 'gp'"
                 class="form-group required">
                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/name.png'  )" alt="Info icon"/>
                  </span>
              <b-form-select
                  ref="gp-agency"
                  aria-label="Select GP practice" v-model="selectedGPPractice" :options="gpPractice"
                  class=""
                  :required="selectedGPPractice === '' || selectedGPPractice!=='none'"
              ></b-form-select>
            </div>
            <div class="form-group required">
                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/name.png'  )" alt="Info icon"/>
                  </span>
              <b-form-select @change="selectAgency" aria-label="Select agency" v-model="selectedAgency"
                             :options="optionsAgency"
                             class=""
                             :required="selectedAgency === '' || selectedAgency!=='none'"
              ></b-form-select>
            </div>
            <div class="form-group required">
              <input aria-label="Other type of agency" v-model="otherAgency"
                     class="form-control  role-other "
                     name="agency-other"
                     id="agency-other"
                     ref="agency-other"
                     :required="selectedAgency !== '' && selectedAgency==='none'"
                     :disabled="selectedAgency === '' || selectedAgency!=='none'"
                     type="text"
                     placeholder="Other">
            </div>
            <div class="form-group required">
                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/name.png'  )" alt="Info icon"/>
                  </span>
              <b-form-select
                  aria-label="Select your knowledge and understanding of identifying domestic abuse"
                  v-model="selectedKnowledge" :options="optionsKnowledge"
                  class=""
                  :required="selectedKnowledge === '' || selectedKnowledge!=='none'"
              ></b-form-select>
            </div>
            <div class="form-group required">
                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/name.png'  )" alt="Info icon"/>
                  </span>
              <b-form-select
                  aria-label="Select your confidence on how to respond to domestic abuse"
                  v-model="selectedConfidence" :options="optionsConfidence"
                  class=""
                  :required="selectedConfidence === '' || selectedConfidence!=='none'"
              ></b-form-select>
            </div>
            <!---- --->
            <div class="form-group required">
                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/name.png'  )" alt="Info icon"/>
                  </span>
              <b-form-select
                  aria-label="Select your knowledge and understanding of identifying domestic abuse"
                  v-model="selectedModernSlaveryKnowledge" :options="optionsModernSlaveryKnowledge"
                  class=""
                  :required="selectedModernSlaveryKnowledge === '' || selectedModernSlaveryKnowledge!=='none'"
              ></b-form-select>
            </div>
            <div class="form-group required">
                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/name.png'  )" alt="Info icon"/>
                  </span>
              <b-form-select
                  aria-label="Select your confidence on how to respond to domestic abuse"
                  v-model="selectedModernSlaveryConfidence" :options="optionsModernSlaveryConfidence"
                  class=""
                  :required="selectedModernSlaveryConfidence === '' || selectedModernSlaveryConfidence!=='none'"
              ></b-form-select>
            </div>


            <div class="form-group required">
                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/name.png'  )" alt="Info icon"/>
                  </span>
              <b-form-select name="county"
                             aria-label="Select county"
                             v-model="selectedCounty"
                             @change="selectCounty"
              >
                <option value="chooseCounty" selected="true" disabled="disabled">In which county are you based?</option>
                <optgroup v-for="(group, name) in optionsCounty" :label="name">
                  <option v-for="option in group" :value="option.value">
                    {{ option.text }}
                  </option>
                </optgroup>

              </b-form-select>

            </div>
            <div class="form-group required">
              <input aria-label="Other county" v-model="otherCounty"
                     class="form-control  role-other "
                     name="county-other"
                     id="county-other"
                     ref="county-other"
                     :required="selectedCounty !== '' && selectedCounty==='none'"
                     :disabled="selectedCounty === '' || selectedCounty!=='none'"
                     type="text"
                     placeholder="Other">
            </div>
            <!--------- -->

            <div class="form-group required">
                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/email.png'  )" alt="Email icon"/>
                  </span>
              <input aria-label="Email" v-model="email" class="form-control " name="email" id="email"
                     type="email" autocomplete="email"
                     placeholder="E.g. example@gmail.com"
                     required="">

            </div>
            <div class="form-group required">
                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/padlock.png'  )" alt="Padlock icon"/>
                  </span>
              <input aria-label="Password" placeholder="Password" v-model="password"
                     class="form-control " name="password" id="password" type="password"
                     autocomplete="new-password" required="" role="password">
            </div>

            <div class="form-group required">
                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/padlock.png'  )" alt="Padlock icon"/>
                  </span>
              <input v-model="confirmPassword" aria-label="Confirm password" placeholder="Confirm password"
                     class="form-control " name="confirmPassword" id="confirmPassword" type="password"
                     autocomplete="new-password" required="" role="password">
            </div>
            <div class="my-3 alert alert-danger" v-if="dBError ">{{ dBError }}</div>
            <div class="my-3 alert alert-danger" v-if="formError">{{ formErrorMessage }}</div>
            <div v-if="validEmail === false" class=" alert w-100 p-3  background-color8 color5">
              This website is restricted to the Somerset area.
              <br/><br/>
              If you are from agencies outside the Somerset area please contact <a
                href="mailto:communitysafetyteam@somerset.gov.uk">communitysafetyteam@somerset.gov.uk</a> to arrange
              access to these
              materials.
              <br/><br/>
              We can arrange for access for individuals, or for organisations, but there might be a fee involved. Please
              get in touch to discuss your requirements.
            </div>
            <div class="my-4 text-center">
              <b-button class="background-color2 w-100 p-3 font-weight-bold" type="submit">Submit answers and register
              </b-button>
            </div>

          </form>

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import "firebase/auth";
import {db} from '../firebaseDatabase';
import {mapState, mapActions, mapGetters} from 'vuex';
import MastHead from "@/components/MastHead";
import PasswordReset from "@/views/PasswordReset";

export default {
  data() {
    return {
      validEmail: null,
      email: "",
      password: "",
      confirmPassword: "",
      forename: "",
      lastname: "",
      formErrorMessage: "",
      otherRole: "",
      selectedRole: "",
      optionsRole: [
        {
          "value": "",
          "text": "What is your current role? Please select the nearest match.",
          "disabled": true,
          "selected": true
        },
        {"value": "administrator", "text": "Administrator"},
        {"value": "commissioner", "text": "Commissioner"},
        {"value": "community-worker-village-agent", "text": "Community Worker/Village Agent"},
        {"value": "dentist", "text": "Dentist or Dentist Assistant"},
        {"value": "director", "text": "Director"},
        {"value": "domestic-abuse-worker", "text": "Domestic Abuse Worker"},
        {"value": "finance-manager", "text": "Finance Manager/Assistant"},
        {"value": "firefighter", "text": "Firefighter"},
        {"value": "gp", "text": "GP"},
        {"value": "healthcare-professional", "text": "Healthcare Professional"},
        {"value": "home-safety-technician", "text": "Home Safety Technician"},
        {"value": "housing-manager-officer", "text": "Housing Manager/Officer"},
        {"value": "legal-professional", "text": "Legal Professional"},
        {"value": "manager-team-leader-supervisor", "text": "Manager/Team Leader/Supervisor"},
        {"value": "police-officer-warranted", "text": "Police Officer (warranted)"},
        {"value": "police-staff", "text": "Police Staff"},
        {"value": "project-officer-manager", "text": "Project Officer/Manager"},
        {"value": "recovery-worker", "text": "Recovery Worker"},
        {"value": "safeguarding-lead", "text": "Safeguarding Lead"},
        {"value": "social-worker-adults", "text": "Social Worker (adults)"},
        {"value": "social-worker-children", "text": "Social Worker (children)"},
        {"value": "support-worker-adults", "text": "Support Worker (adults)"},
        {"value": "support-worker-children-family", "text": "Support Worker (children/family)"},
        {"value": "teacher", "text": "Teacher"},
        {"value": "teacher-assistant", "text": "Teacher/Teaching Assistant"},
        {"value": "veterinary-surgeon", "text": "Veterinary Surgeon"},
        {"value": "volunteer", "text": "Volunteer"}
      ],
      selectedGPPractice: "",
      gpPractice: [
        {"value": "", "text": "What is your GP practice?", "disabled": true, "selected": true},
        {"value": "ariel-healthcare-chard-practices", "text": "Ariel Healthcare (Chard Practices)"},
        {"value": "axbridge-wedmore-medical-practice", "text": "Axbridge & Wedmore Medical Practice"},
        {"value": "beckington-family-practice", "text": "Beckington Family Practice"},
        {"value": "brent-area-medical-centre", "text": "Brent Area Medical Centre"},
        {"value": "bruton-surgery", "text": "Bruton Surgery"},
        {"value": "burnham-berrow-medical-centre", "text": "Burnham & Berrow Medical Centre"},
        {
          "value": "buttercross-health-centre-ilchester-surgery",
          "text": "Buttercross Health Centre and Ilchester Surgery"
        },
        {"value": "cannington-health-centre", "text": "Cannington Health Centre"},
        {"value": "cheddar-medical-centre", "text": "Cheddar Medical Centre"},
        {"value": "church-view-medical-centre", "text": "Church View Medical Centre"},
        {"value": "college-way-surgery", "text": "College Way Surgery"},
        {"value": "cranleigh-gardens-medical-centre", "text": "Cranleigh Gardens Medical Centre"},
        {"value": "creech-medical-centre", "text": "Creech Medical Centre"},
        {"value": "crewkerne-health-centre", "text": "Crewkerne Health Centre"},
        {"value": "crown-medical-centre", "text": "Crown Medical Centre"},
        {"value": "diamond-health-group-hendford-abbey", "text": "Diamond Health Group (Hendford and Abbey)"},
        {"value": "dunster-porlock-surgeries", "text": "Dunster & Porlock Surgeries"},
        {"value": "east-quay-medical-centre", "text": "East Quay Medical Centre"},
        {"value": "exmoor-medical-centre", "text": "Exmoor Medical Centre"},
        {"value": "french-weir-health-centre", "text": "French Weir Health Centre"},
        {"value": "frome-medical-practice", "text": "Frome Medical Practice"},
        {"value": "glastonbury-health-centre", "text": "Glastonbury Health Centre"},
        {"value": "glastonbury-surgery", "text": "Glastonbury Surgery"},
        {"value": "grove-house-surgery", "text": "Grove House Surgery"},
        {"value": "hamdon-medical-centre", "text": "Hamdon Medical Centre"},
        {"value": "highbridge-medical-centre", "text": "Highbridge Medical Centre"},
        {"value": "langport-surgery", "text": "Langport Surgery"},
        {"value": "lister-house-surgery", "text": "Lister House Surgery"},
        {"value": "luson-surgery", "text": "Luson Surgery"},
        {"value": "lyngford-park-surgery", "text": "Lyngford Park Surgery"},
        {"value": "martock-surgery-south-petherton-mc", "text": "Martock Surgery and South Petherton MC"},
        {"value": "meadows-surgery", "text": "Meadows Surgery"},
        {"value": "mendip-country-practice", "text": "Mendip Country Practice"},
        {"value": "milborne-port-surgery", "text": "Milborne Port Surgery"},
        {"value": "minehead-medical-centre", "text": "Minehead Medical Centre"},
        {"value": "north-curry-health-centre", "text": "North Curry Health Centre"},
        {"value": "north-petherton-surgery", "text": "North Petherton Surgery"},
        {"value": "oakhill-surgery", "text": "Oakhill Surgery"},
        {"value": "oaklands-surgery", "text": "Oaklands Surgery"},
        {"value": "park-medical-practice", "text": "Park Medical Practice"},
        {"value": "penn-hill-surgery", "text": "Penn Hill Surgery"},
        {"value": "polden-medical-practice", "text": "Polden Medical Practice"},
        {"value": "preston-grove-medical-centre", "text": "Preston Grove Medical Centre"},
        {"value": "quantock-medical-centre", "text": "Quantock Medical Centre"},
        {"value": "quantock-vale-surgery", "text": "Quantock Vale Surgery"},
        {"value": "queen-camel-medical-centre", "text": "Queen Camel Medical Centre"},
        {"value": "redgate-medical-centre-somerset-bridge", "text": "Redgate Medical Centre and Somerset Bridge"},
        {"value": "ryalls-park-medical-centre", "text": "Ryalls Park Medical Centre"},
        {"value": "st-james-medical-centre", "text": "St James Medical Centre"},
        {"value": "summervale-surgery", "text": "Summervale Surgery"},
        {"value": "taunton-road-medical-centre", "text": "Taunton Road Medical Centre"},
        {"value": "taunton-vale-healthcare", "text": "Taunton Vale Healthcare"},
        {"value": "vine-surgery-partnership", "text": "Vine Surgery Partnership"},
        {"value": "warwick-house-medical-practice", "text": "Warwick House Medical Practice"},
        {"value": "wellington-medical-centre", "text": "Wellington Medical Centre"},
        {"value": "wells-city-practice", "text": "Wells City Practice"},
        {"value": "wells-health-centre", "text": "Wells Health Centre"},
        {"value": "west-coker-surgery", "text": "West Coker Surgery"},
        {"value": "west-somerset-healthcare-williton-watchet", "text": "West Somerset Healthcare (Williton & Watchet)"},
        {"value": "wincanton-health-centre", "text": "Wincanton Health Centre"}
      ],
      otherAgency: "",
      selectedAgency: "",
      optionsAgency: [
        {"value": "", "text": "What is your current agency?", "disabled": true, "selected": true},
        {"value": "ambulance-service", "text": "Ambulance Service"},
        {"value": "fire-rescue-service", "text": "Fire and Rescue Service"},
        {"value": "gp-surgery", "text": "GP Surgery"},
        {"value": "hospital", "text": "Hospital"},
        {"value": "housing-association", "text": "Housing Association"},
        {"value": "local-authority-adults", "text": "Local Authority Adults"},
        {"value": "local-authority-children", "text": "Local Authority Children"},
        {"value": "local-authority-housing-dept", "text": "Local Authority Housing Dept"},
        {"value": "local-authority-other", "text": "Local Authority - other (not stated above)"},
        {"value": "nursery-early-years-setting", "text": "Nursery/Early Years Setting"},
        {"value": "police", "text": "Police"},
        {"value": "probation", "text": "Probation"},
        {"value": "public-health", "text": "Public Health"},
        {"value": "school", "text": "School"},
        {"value": "college", "text": "College"},
        {
          "value": "domestic-abuse-sexual-violence-specialist-service",
          "text": "Domestic Abuse/Sexual Violence Specialist Service"
        },
        {"value": "none", "text": "Not listed - enter agency below"}
      ],
      selectedKnowledge: "",
      optionsKnowledge: [
        {
          "value": "",
          "text": "How would you rate your knowledge and understanding of identifying domestic abuse?",
          "disabled": true,
          "selected": true
        },
        {
          "value": "not-at-all-knowledgeable",
          "text": "Not at all knowledgeable"
        },
        {
          "value": "slightly-knowledgeable",
          "text": "Slightly knowledgeable"
        },
        {
          "value": "somewhat-knowledgeable",
          "text": "Somewhat knowledgeable"
        },
        {
          "value": "fairly-knowledgeable",
          "text": "Fairly knowledgeable"
        },
        {
          "value": "very-knowledgeable",
          "text": "Very knowledgeable"
        }
      ],
      selectedConfidence: "",
      optionsConfidence: [
        {
          "value": "",
          "text": "How would you rate your confidence on how to respond to domestic abuse?",
          "disabled": true,
          "selected": true
        },
        {
          "value": "not-at-all-confident",
          "text": "Not at all confident"
        },
        {
          "value": "slightly-confident",
          "text": "Slightly confident"
        },
        {
          "value": "somewhat-confident",
          "text": "Somewhat confident"
        },
        {
          "value": "fairly-confident",
          "text": "Fairly confident"
        },
        {
          "value": "very-confident",
          "text": "Very confident"
        }
      ],
      selectedModernSlaveryKnowledge: "",
      optionsModernSlaveryKnowledge: [
        {
          "value": "",
          "text": "How would you rate your knowledge and understanding of identifying modern slavery?",
          "disabled": true,
          "selected": true
        },
        {
          "value": "not-at-all-knowledgeable",
          "text": "Not at all knowledgeable"
        },
        {
          "value": "slightly-knowledgeable",
          "text": "Slightly knowledgeable"
        },
        {
          "value": "somewhat-knowledgeable",
          "text": "Somewhat knowledgeable"
        },
        {
          "value": "fairly-knowledgeable",
          "text": "Fairly knowledgeable"
        },
        {
          "value": "very-knowledgeable",
          "text": "Very knowledgeable"
        }
      ],
      selectedModernSlaveryConfidence: "",
      optionsModernSlaveryConfidence: [
        {
          "value": "",
          "text": "How would you rate your confidence on how to respond to modern slavery?",
          "disabled": true,
          "selected": true
        },
        {
          "value": "not-at-all-confident",
          "text": "Not at all confident"
        },
        {
          "value": "slightly-confident",
          "text": "Slightly confident"
        },
        {
          "value": "somewhat-confident",
          "text": "Somewhat confident"
        },
        {
          "value": "fairly-confident",
          "text": "Fairly confident"
        },
        {
          "value": "very-confident",
          "text": "Very confident"
        }
      ],


      selectedCounty: "chooseCounty",
      optionsCounty: {
        'England': [
          {text: 'Bedfordshire', value: 'Bedfordshire'},
          {text: 'Berkshire', value: 'Berkshire'},
          {text: 'Bristol', value: 'Bristol'},
          {text: 'Buckinghamshire', value: 'Buckinghamshire'},
          {text: 'Cambridgeshire', value: 'Cambridgeshire'},
          {text: 'Cheshire', value: 'Cheshire'},
          {text: 'City of London', value: 'City of London'},
          {text: 'Cornwall', value: 'Cornwall'},
          {text: 'Cumbria', value: 'Cumbria'},
          {text: 'Derbyshire', value: 'Derbyshire'},
          {text: 'Devon', value: 'Devon'},
          {text: 'Dorset', value: 'Dorset'},
          {text: 'Durham', value: 'Durham'},
          {text: 'East Riding of Yorkshire', value: 'East Riding of Yorkshire'},
          {text: 'East Sussex', value: 'East Sussex'},
          {text: 'Essex', value: 'Essex'},
          {text: 'Gloucestershire', value: 'Gloucestershire'},
          {text: 'Greater London', value: 'Greater London'},
          {text: 'Greater Manchester', value: 'Greater Manchester'},
          {text: 'Hampshire', value: 'Hampshire'},
          {text: 'Herefordshire', value: 'Herefordshire'},
          {text: 'Hertfordshire', value: 'Hertfordshire'},
          {text: 'Isle of Wight', value: 'Isle of Wight'},
          {text: 'Kent', value: 'Kent'},
          {text: 'Lancashire', value: 'Lancashire'},
          {text: 'Leicestershire', value: 'Leicestershire'},
          {text: 'Lincolnshire', value: 'Lincolnshire'},
          {text: 'Merseyside', value: 'Merseyside'},
          {text: 'Norfolk', value: 'Norfolk'},
          {text: 'North Yorkshire', value: 'North Yorkshire'},
          {text: 'Northamptonshire', value: 'Northamptonshire'},
          {text: 'Northumberland', value: 'Northumberland'},
          {text: 'Nottinghamshire', value: 'Nottinghamshire'},
          {text: 'Oxfordshire', value: 'Oxfordshire'},
          {text: 'Rutland', value: 'Rutland'},
          {text: 'Shropshire', value: 'Shropshire'},
          {text: 'Somerset', value: 'Somerset'},
          {text: 'South Yorkshire', value: 'South Yorkshire'},
          {text: 'Staffordshire', value: 'Staffordshire'},
          {text: 'Suffolk', value: 'Suffolk'},
          {text: 'Surrey', value: 'Surrey'},
          {text: 'Tyne and Wear', value: 'Tyne and Wear'},
          {text: 'Warwickshire', value: 'Warwickshire'},
          {text: 'West Midlands', value: 'West Midlands'},
          {text: 'West Sussex', value: 'West Sussex'},
          {text: 'West Yorkshire', value: 'West Yorkshire'},
          {text: 'Wiltshire', value: 'Wiltshire'},
          {text: 'Worcestershire', value: 'Worcestershire'},
        ],
        'Wales': [
          {text: 'Anglesey', value: 'Anglesey'},
          {text: 'Brecknockshire', value: 'Brecknockshire'},
          {text: 'Caernarfonshire', value: 'Caernarfonshire'},
          {text: 'Carmarthenshire', value: 'Carmarthenshire'},
          {text: 'Cardiganshire', value: 'Cardiganshire'},
          {text: 'Denbighshire', value: 'Denbighshire'},
          {text: 'Flintshire', value: 'Flintshire'},
          {text: 'Glamorgan', value: 'Glamorgan'},
          {text: 'Merioneth', value: 'Merioneth'},
          {text: 'Monmouthshire', value: 'Monmouthshire'},
          {text: 'Montgomeryshire', value: 'Montgomeryshire'},
          {text: 'Pembrokeshire', value: 'Pembrokeshire'},
          {text: 'Radnorshire', value: 'Radnorshire'},
        ],
        'Scotland': [
          {text: 'Aberdeenshire', value: 'Aberdeenshire'},
          {text: 'Angus', value: 'Angus'},
          {text: 'Argyllshire', value: 'Argyllshire'},
          {text: 'Ayrshire', value: 'Ayrshire'},
          {text: 'Banffshire', value: 'Banffshire'},
          {text: 'Berwickshire', value: 'Berwickshire'},
          {text: 'Buteshire', value: 'Buteshire'},
          {text: 'Cromartyshire', value: 'Cromartyshire'},
          {text: 'Caithness', value: 'Caithness'},
          {text: 'Clackmannanshire', value: 'Clackmannanshire'},
          {text: 'Dumfriesshire', value: 'Dumfriesshire'},
          {text: 'Dunbartonshire', value: 'Dunbartonshire'},
          {text: 'East Lothian', value: 'East Lothian'},
          {text: 'Fife', value: 'Fife'},
          {text: 'Inverness-shire', value: 'Inverness-shire'},
          {text: 'Kincardineshire', value: 'Kincardineshire'},
          {text: 'Kinross', value: 'Kinross'},
          {text: 'Kirkcudbrightshire', value: 'Kirkcudbrightshire'},
          {text: 'Lanarkshire', value: 'Lanarkshire'},
          {text: 'Midlothian', value: 'Midlothian'},
          {text: 'Morayshire', value: 'Morayshire'},
          {text: 'Nairnshire', value: 'Nairnshire'},
          {text: 'Orkney', value: 'Orkney'},
          {text: 'Peeblesshire', value: 'Peeblesshire'},
          {text: 'Perthshire', value: 'Perthshire'},
          {text: 'Renfrewshire', value: 'Renfrewshire'},
          {text: 'Ross-shire', value: 'Ross-shire'},
          {text: 'Roxburghshire', value: 'Roxburghshire'},
          {text: 'Selkirkshire', value: 'Selkirkshire'},
          {text: 'Shetland', value: 'Shetland'},
          {text: 'Stirlingshire', value: 'Stirlingshire'},
          {text: 'Sutherland', value: 'Sutherland'},
          {text: 'West Lothian', value: 'West Lothian'},
          {text: 'Wigtownshire', value: 'Wigtownshire'},
        ],
        'Northern Ireland': [
          {text: 'Antrim', value: 'Antrim'},
          {text: 'Armagh', value: 'Armagh'},
          {text: 'Down', value: 'Down'},
          {text: 'Fermanagh', value: 'Fermanagh'},
          {text: 'Londonderry', value: 'Londonderry'},
          {text: 'Tyrone', value: 'Tyrone'},
        ],
        'Not listed': [
          {text: 'Enter county below', value: 'none'}
        ]

      },
      otherCounty: ""
    };
  },
  components: {
    MastHead
  },
  computed: {
    ...mapState([
      'FIREBASE_UID',
      'DEBUG_STATUS'
    ]),
    ...mapGetters([
      'GET_FB_USER_DATA',
      'GET_FB_ERROR',
      'GET_FB_LOADING_STATUS'
    ]),

    user() {
      return this.GET_FB_USER_DATA;
    },
    dBError() {
      return this.GET_FB_ERROR;
    },
    loading() {
      return this.GET_FB_LOADING_STATUS;
    },
    formError() {
      this.formErrorMessage = "";
      if (this.password !== this.confirmPassword && this.confirmPassword !== "") {
        this.formErrorMessage = "Error: Passwords do not match.";
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push('/')
      }
    }
  },
  methods: {
    ...mapActions([
      'ACTION_FB_REGISTER_USER'
    ]),
    selectGPPractice() {
      if (this.selectedRole === 'gp') {
        this.$refs['gp-agency'].focus()
      }
    },
    selectAgency() {
      if (this.selectedAgency === 'none') {
        this.$refs['agency-other'].focus()
      }
    },
    selectCounty() {
      if (this.selectedCounty === 'none') {
        this.$refs['county-other'].focus()
      }
    },
    register() {

      if (!this.formError) {

        this.ACTION_FB_REGISTER_USER({
          forename: this.forename,
          lastname: this.lastname,
          selectedRole: this.selectedRole,
          otherRole: this.otherRole,
          selectedAgency: this.selectedAgency,
          selectedGPPractice: this.selectedGPPractice,
          otherAgency: this.otherAgency,
          selectedKnowledge: this.selectedKnowledge,
          selectedConfidence: this.selectedConfidence,
          selectedModernSlaveryKnowledge: this.selectedModernSlaveryKnowledge,
          selectedModernSlaveryConfidence: this.selectedModernSlaveryConfidence,
          selectedCounty: this.selectedCounty,
          otherCounty: this.otherCounty,
          email: this.email,
          password: this.password
        });


      }

    },
    getWhiteListedEmails() {
      const getWhitelist = firebase
          .functions()
          .httpsCallable('getWhitelist');

      let self = this;

      getWhitelist(this.email)
          .then(function (emailValidationResult) {
            // console.log("getWhitelist", emailValidationResult.data);
            self.validEmail = emailValidationResult.data;

            if (emailValidationResult.data === true) {
              //console.log("REGISTER");
              self.register();
            }

          })
          .catch(function (error) {
            // Getting the Error details.
            console.log(error);

          });
    },
    /*
        checkEmailAgainstWhiteList() {
          db.collection("whitelist").doc("allowed").get()
              .then(snapshot => {
                // get the whitelist from FB
                const whiteListedDomains = snapshot.data().domains;
                const whiteListedEmails = snapshot.data().emails;
                const whiteListedTLDs = snapshot.data().tlds;
                // Concatenate the 3 arrays
                const whiteList =  whiteListedDomains.concat(whiteListedEmails, whiteListedTLDs);
                const emailPartToBeChecked = this.email;
                //Check if the email is valid against the whitelist
                let validEmail = this.isEmailValid (emailPartToBeChecked, whiteList);

                if(validEmail) {
                  this.validEmail = true;
                  this.register();
                }else {
                  this.validEmail = false;
                }

              })
              .catch(error => {
                // Handle error
                console.log(error);
              });
        },
        isEmailValid (emailPartToBeChecked, whiteListedEmails) {

          let whitelist = whiteListedEmails;
          //Is the email in the list?
          if(whitelist.includes(emailPartToBeChecked)) {
            return true;
          } else{
            //Check against the domain name or TLD
            //Get the domain name
            const emailParts = emailPartToBeChecked.split('@');
            let domainName = emailParts[1];

            // Get the tld parts
            const domainParts = domainName.split('.');
            const tldParts = domainParts.slice(-2);
            const tldLast = tldParts[tldParts.length -1];
            const tldUK = `${tldParts[tldParts.length -2]}.${tldParts[tldParts.length -1]}`;

            //return true if domain name OR UK TLD is in the whitelist
            return whitelist.includes(domainName) || whitelist.includes(tldUK);
          }
        }

        */

  }
};
</script>

<style lang="scss" scoped>

a, a:link, a:hover, a:active, a:visited {
  text-decoration: underline;
}

input, select {
  width: 100% !important;
}

.form-group {
  display: flex;
}

.form-container {
  max-width: 500px;
}

input[type='checkbox'] {
  width: auto;
}

.btn {
  border: none;
  width: 500px;
  font-size: 1.1em;
}

.alert {
  width: 500px;
}

.role-other {
  margin-left: 45px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;

  img {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 768px) {
  select {
    width: 80%;
    max-width: 80% !important;
  }
}

</style>
